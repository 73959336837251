import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import OGImage from 'images/McCordsville-Dentist.png'
import Logo from 'images/logo.png'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const DrVogtPage: React.FC<Props> = ({ data, location }: Props) => {
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_dr_img = data.hero_dr_img?.childImageSharp?.fluid
  const section3_img = data.section3_img?.childImageSharp?.fluid
  const sec1_img = data.sec1_img?.childImageSharp?.fluid
  const sec2_img = data.sec2_img?.childImageSharp?.fluid
  const section4_background = data.office_section4_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Dr. Vogt  - The Dentists At Gateway Crossing</title>
      <meta name="description" content="Doctor Of Dental Surgery" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Dr. Vogt - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Doctor Of Dental Surgery" />
      <meta property="og:url" content="https://thedentistsatgc.com/about/dr-vogt/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="600" />
      <meta property="og:image:alt" content="Dentist in McCordsville" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Dr. Vogt - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Doctor Of Dental Surgery" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg hero-section-dr"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateaway Crossing"
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-8 text-center">
              <p className="hero-text">
                MEET YOUR MCCORDSVILLE DENTIST
              </p>
              <h1 className="text-light">
                Dr. Matthew Vogt
              </h1>
              <a href="#dr-section-1"><button className="btn-rounded m-t-35"> Learn More </button></a>
              <Link to="/contact"><button className="btn-rounded m-t-35"> Schedule Now </button></Link>
            </div>
            <div className="col-lg-4 text-center">
              <Img fluid ={hero_dr_img} alt="Dr. Matthew Vogt - McCordsville Dentist" />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id ="dr-section-1" className="page-section text-left">
          <Container>
            <div className="col-lg-4">
              <Img fluid ={section3_img} alt="Dr. Matthew Vogt and the McCordsville Dentists" />
            </div>
            <div className="col-lg-8">
              <p className ="section-1-text">DOCTOR OF DENTAL SURGERY </p>
              <h2 className ="section-1-header">Meet Dr. Matthew Vogt</h2>
              <p>Dr. Matthew Vogt started The Dentists at Gateway Crossing in 2018 with the goal of providing quality modern dental care and an exceptional patient experience. Today, the practice has grown to become a pillar of the local community, serving thousands of happy patients from McCordsville and all of the greater Indianapolis area.</p>
              <p>Dr. Vogt was born and raised in Pittsburgh, PA, and now lives in Indianapolis with his wife Hilary and two rescue dogs. He received his Bachelor of Science degree in Biology from Butler University, and his Doctor of Dental Surgery degree from Indiana University School of Dentistry, where he graduated with the Academy of Osseointegration Award, which recognizes one student each year for exceptional skills in oral surgery and implant dentistry. </p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12">
              <p className="m-t-20">His post-graduate continuing education has focused on all areas of clinical dentistry, including the placement and restoration of single and full-mouth dental implants. He has completed training with Spear Education, Academy of General Dentistry, Implant Pathway, and Aesthetic Interface, and is a member of the American Dental Association, Indiana Dental Association, and Indianapolis District Dental Society.</p>
              <p>Outside of the office, he enjoys fitness, live music, eating pizza, and spending time with family. He is also passionate about competitive amateur golf and regularly competes in tournaments at the local, state, and national levels.</p>
              <p>Dr. Vogt and our team look forward to meeting you!</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-6">
            <Img fluid ={sec1_img} className="dr-vogt-pic" alt="Dr. Matthew Vogt and the McCordsville Dentists" />
            </div>
            <div className="col-lg-6">
            <Img fluid ={sec2_img} className="dr-vogt-pic" alt="Dr. Matthew Vogt and the McCordsville Dentists" />
            </div>
          </Container>
        </section>
        <BackgroundImage
            Tag="section"
            className="office_section4-section-bg"
            fluid={section4_background}
            backgroundColor={`#e9f4f8`}
            alt="The Dentists at Gateaway Crossing Book an Appointment"
          >
            <div className ="col-lg-12" id ="color-overlay-dark"></div>
            <section id="section-4" className="page-section text-center">
              <Container>
                <div className="col-lg-12 ">
                  <button className="btn-rounded"> Book An Appointment </button>
                </div>
              </Container>
              </section>
        </BackgroundImage>
    </Layout>
  )
}

export default DrVogtPage

export const query = graphql`
  query DrVogtPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_dr_img: file(name: { eq: "best-mathew-v" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section3_img: file(name: { eq: "dr-mathew-vogt" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    sec1_img: file(name: { eq: "dr-vogt-img1" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    sec2_img: file(name: { eq: "dr-vogt-img2" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section4_background: file(name: { eq: "section_4_bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
